import { uploadImgUrl } from '@/common/utils/config.js';
export default {
  data() {
    return {
      uploadImgUrl: uploadImgUrl,
      dialogImageUrl: '',
      //预览图片地址
      img_list: [],
      //上传图片列表
      previewImgDialog: false,
      num: '',
      //广告天数
      positionList: [],
      //广告位置列表
      ads_type_id: '',
      //广告位id
      adPrice: 0,
      //广告价格

      information_id: '',
      //

      page: 1,
      pageSize: 10,
      total: '',
      myAdContentList: [],
      //所有广告内容

      adInfor: '',
      //
      id: '',
      end_time: '',
      //到期时间

      fileList: [] //回显展示图片
    };
  },

  mounted() {
    this.id = this.$route.query.id;
    this.get_ads_list();
    this.getAllAd();
    this.myAdsInfo();
  },
  methods: {
    // 获取编辑广告的信息
    myAdsInfo() {
      this.$http.myAdsInfo({
        id: this.id
      }).then(res => {
        if (res.code == 1) {
          this.adInfor = res.data;
          this.ads_type_id = this.adInfor.ads_type;
          this.information = this.adInfor.information_name;
          this.information_id = this.adInfor.bind_information_id;
          this.end_time = this.adInfor.end_time;
          this.fileList = [{
            name: this.adInfor.thumb,
            url: this.adInfor.thumb_url
          }];
        } else {
          this.$message.info(res.msg);
        }
      });
    },
    // 所选广告内容下拉加载更多
    loadMore() {
      if (this.myAdContentList.length < this.total) {
        this.page++;
      }
    },
    getAllAd() {
      let obj = {
        page: this.page,
        pageSize: this.pageSize,
        check: 2
      };
      this.$http.msgList(obj).then(res => {
        if (res.code == 1) {
          this.myAdContentList = res.data.data;
          this.total = res.data.total;
          console.log("-0999任", this.myAdContentList);
        } else {}
      });
    },
    // 下拉框筛选事件
    selectChange(e) {
      console.log(e);
      this.adPrice = this.positionList.filter(item => item.id == e)[0].price;
      console.log(this.adPrice);
    },
    // 获取广告位置
    get_ads_list() {
      this.$http.get_ads_list().then(res => {
        if (res.code == 1) {
          console.log();
          this.positionList = res.data;
        } else {
          this.$message.info(res.msg);
        }
      });
    },
    // 返回
    backClick() {
      this.$router.back();
    },
    // 图片删除
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.img_list = [];
    },
    // 图片预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.previewImgDialog = true;
    },
    // 上传之前
    beforeUpload(file) {
      const fileName = file.name;
      const fileType = fileName.substring(fileName.lastIndexOf('.'));
      if (fileType === '.jpg' || fileType === '.png' || fileType === '.jpeg' || fileType === '.bmp' || fileType === '.gif') {} else {
        this.$message.error(this.$t('pleaseUploadTheCorrectImageType'));
        return false;
      }
    },
    // 上传图片成功
    onChange(file, fileList) {
      console.log(file);
      console.log(fileList);
      this.img_list = fileList;
    },
    // 提交
    btnClick() {
      if (this.isFlag) return;
      let image;
      if (this.img_list.length > 0) {
        image = this.img_list.map(item => item.response.data[0].id).join(",");
      } else {
        image = this.fileList.map(item => item.name).join(",");
      }
      if (!image) {
        this.$message.error(this.$t('tips.atLeastOnePhoto'));
        return;
      }
      let data = {
        id: this.id,
        thumb: image,
        bind_information_id: this.information_id
      };
      this.isFlag = true;
      this.$http.operation(data).then(res => {
        if (res.code == 1) {
          this.$message.success(res.msg);
          this.$router.back();
          this.isFlag = false;
        } else {
          this.isFlag = false;
          this.$message.error(res.msg);
        }
      });
    }
  }
};